import React from 'react';
import styled from 'styled-components';
import {useI18next, Link, Trans, Helmet} from 'gatsby-plugin-react-i18next';
import {styles, media, links} from 'global.config';
import Stars from 'components/Stars';
import Button from 'components/Button';
import Container from 'components/Container';
import {PricingPlan} from 'components/Pricing/PricingPlanInterface';
import {AnchorLink} from 'components/AnchorLink';

import AsSeenOn from 'components/Alternatives/AsSeenOn';
import WhyUse from 'components/Alternatives/WhyUse';
import CheckCells from 'components/Alternatives/CheckCells';
import {
  ComparisonBlock,
  ComparisonTitle,
  PriceCompareTable,
  FeatureCompareTable,
  ComparisonHeadline,
  HeadingRow
} from 'components/Alternatives/ComparisonComponents';
import {
  Features,
  FeatureImage,
  FeatureList,
  FeatureItem,
  FeatureContent
} from 'components/Alternatives/FeatureComponents';
import CallToAction from 'components/Alternatives/CallToAction';
import MoonmailButton from 'components/Button';

import EmailMarketingSoftwareImage from 'images/email-marketing-software.svg';
import MoonmailLogo from 'images/moonmail-logo-full.svg';
import Sendy from 'images/sendy-logo.jpg';

import FasterEmail from 'images/faster-email.svg';
import Cleaning from 'images/cleaning.svg';
import UnlimitedSubs from 'images/unlimited-subs.svg';
import SimplePricing from 'images/simple-pricing.svg';
import PersonalizationTags from 'images/personalization-tags.svg';
import MultilingualChat from 'images/multilingual-chat.svg';

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionContainer = styled.div`
  padding: 135px ${styles.containerPadding} 80px;
  position: relative;
  min-height: calc(100vh - 170px);
  margin: auto;
  max-width: ${styles.containerWidth};
  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const IndexSectionImage = styled.img`
  display: block;
  margin: 0 auto;
  @media ${media.mobile} {
    width: 235px;
    margin-bottom: 20px;
  }
`;

const IndexSectionSummary = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: 400px;
  margin: 30px auto;
  font-weight: 300;
`;

const alternative = 'Sendy';

const Page: React.FC<{pricingPlans: PricingPlan[]}> = () => {
  const {t} = useI18next();
  return (
    <>
      <Helmet title={t('{{alternative}} alternative', {alternative})}>
        <meta
          name="description"
          content={t(
            `Looking for a {{alternative}} Alternative? MoonMail is your choice if you're looking for the email marketing platform for the professional marketer. Click here to learn more!`,
            {alternative}
          )}
        />
      </Helmet>
      <div className="index-page">
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <IndexSectionImage
                src={EmailMarketingSoftwareImage}
                alt={t('Email marketing software')}
                title={t('OmniChannel Communication Platform')}
                width={296}
                height={201}
              />
              <h1>{t('Looking for a {{alternative}} Alternative?', {alternative})}</h1>
              <IndexSectionSummary>
                <Trans>
                  MoonMail is your choice if you’re looking for the real {{alternative}}{' '}
                  alternative.
                </Trans>
              </IndexSectionSummary>
              <p>
                <Button
                  href={links.signUp}
                  title={t('OmniChannel Communication Platform to fly to the moon')}>
                  {t('Switch to MoonMail now')}
                </Button>
              </p>
            </IndexSectionContainer>
          </IndexSection>
          <AsSeenOn />
        </Stars>
        <section>
          <Container>
            <WhyUse name={alternative}>
              <Trans>
                MoonMail is the ideal {{alternative}} alternative for your email marketing strategy
                as it’s a cloud based solution you can immediately use after registration. Don’t
                waste your time installing applications into a self hosted server. Forget about
                servers maintenance. Avoid deliverability risks and take full control over
                performance and security. Send your emails 10x faster and enjoy crystal clear
                transparent costs.
              </Trans>
            </WhyUse>
          </Container>
          <ComparisonTitle>{t('Pricing Comparison per One Month')}</ComparisonTitle>
          <ComparisonBlock>
            <PriceCompareTable>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <img
                      src={MoonmailLogo}
                      alt={t('MoonMail vs {{alternative}}', {alternative})}
                      title={t('MoonMail vs {{alternative}}', {alternative})}
                    />
                  </th>
                  <th>
                    <img
                      src={Sendy}
                      title={t('{{alternative}} alternative', {alternative})}
                      alt={t('{{alternative}} alternative', {alternative})}
                      width="75"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <Trans>
                  <tr>
                    <td>Free Plan</td>
                    <CheckCells values={[true, false]} />
                  </tr>
                  <tr>
                    <td>License Fee</td>
                    <td>$0.00</td>
                    <td>$59</td>
                  </tr>
                  <tr>
                    <td>Setup Fee</td>
                    <td>$0.00</td>
                    <td>$299 *</td>
                  </tr>
                  <tr>
                    <td>Monthly fee</td>
                    <td>$19.99</td>
                    <td>From $33 /month - Hosting fee **</td>
                  </tr>
                  <tr>
                    <td>Free updates</td>
                    <CheckCells values={[true, false]} />
                  </tr>
                  <tr>
                    <td>Your time</td>
                    <td>$0.00</td>
                    <td>$2,000 ***</td>
                  </tr>
                  <tr>
                    <td>Peace of mind</td>
                    <td>$0.00</td>
                    <td>$10,000 ****</td>
                  </tr>
                </Trans>
              </tbody>
            </PriceCompareTable>
          </ComparisonBlock>
          <ComparisonHeadline>
            <Trans>
              <p>* 3 hours worth php developer at 100 usd/hour.</p>
              <p>
                ** t2.medium AWS ec2 instance monthly charge (minimum computational performance for
                sending 50.000 emails in less than 4 hours).
              </p>
              <p>
                *** Getting {{alternative}} up and running is going to be minimum one week, really,
                believe us. We value your time more than anyone.
              </p>
              <p>**** What if a hacker gets into your {{alternative}} deployment? No more words.</p>
              <p>
                The license for {{alternative}} is required in order to be run on your hosting.{' '}
                {{alternative}} is really hard to install and configure, let alone the server
                configuration and PHP environment itself. With MoonMail you don’t need any technical
                knowledge or developer support: No installations, no configurations, no updates, no
                patch releases!
              </p>
            </Trans>
          </ComparisonHeadline>
          <ComparisonTitle>{t('Feature Summary')}</ComparisonTitle>
          <ComparisonBlock>
            <FeatureCompareTable>
              <tr>
                <th></th>
                <th>
                  <img
                    src={MoonmailLogo}
                    alt={t('MoonMail vs {{alternative}}', {alternative})}
                    title={t('MoonMail vs {{alternative}}', {alternative})}
                  />
                </th>
                <th>
                  <img
                    src={Sendy}
                    title={t('{{alternative}} alternative', {alternative})}
                    alt={t('{{alternative}} alternative', {alternative})}
                    width="75"
                  />
                </th>
              </tr>
              <tbody>
                <tr>
                  <td>{t('Fast Setup')}</td>
                  <CheckCells
                    values={[true, false]}
                    notes={['', t('Install software on server')]}
                  />
                </tr>
                <tr>
                  <td>{t('Real time platform updates')}</td>
                  <CheckCells values={[true, false]} notes={['', t('Need to install patches')]} />
                </tr>
                <tr>
                  <td>{t('SES connection')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Cloud')}</td>
                  <CheckCells values={[true, false]} notes={['', t('Pay for the server')]} />
                </tr>
                <tr>
                  <td>{t('Fast email delivery')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Drag and drop email editor')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Automations')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('List Segmentation')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('A/B Testing')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Pre made templates')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Reports and Analytics')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td> {t('Multiple users')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Multilingual Interface')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Mulitilingual Live Chat Support')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>
                    <Link to="/email-marketing-affiliate-program/">
                      {t('Make money with the platform')}
                    </Link>
                  </td>
                  <CheckCells values={[true, false]} />
                </tr>
                <HeadingRow>
                  <td>{t('For the techy ones...')}</td>
                  <td></td>
                  <td></td>
                </HeadingRow>
                <tr>
                  <td>{t('Choose double opt in or not in your web forms')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Attach files*')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('RSS to email*')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Free schema.org markup assessment')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Fully Open Source Project')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>
                    <Trans>
                      Deploy the whole software into your own Amazon
                      <br />
                      Web Services Account
                    </Trans>
                  </td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Use your own dedicated IP')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
              </tbody>
            </FeatureCompareTable>
          </ComparisonBlock>
          <CallToAction>
            <ComparisonTitle>
              {t('Switch now from {{alternative}} to MoonMail!', {alternative})}
            </ComparisonTitle>
            <MoonmailButton href={links.signUp} title={t('Try MoonMail for Free')}>
              {t('Signup for Free')}
            </MoonmailButton>
          </CallToAction>
        </section>
        <Features>
          <Container>
            <FeatureList>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={UnlimitedSubs}
                    alt={t('The best {{alternative}} alternative has unlimited subscribers', {
                      alternative
                    })}
                    title={t('The best {{alternative}} alternative has unlimited subscribers', {
                      alternative
                    })}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>{t('Free and fast setup')}</ComparisonTitle>
                  <Trans parent="p">
                    Installation of {{alternative}} on server and integration with Amazon SES takes
                    a lot of technical skills and you will need to hire someone for sure. MoonMail
                    is a great alternative as you can set it up literally in 5 minutes with no
                    servers or additional costs.
                  </Trans>
                </FeatureContent>
              </FeatureItem>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={SimplePricing}
                    alt={t('The best {{alternative}} alternative has simple pricing', {
                      alternative
                    })}
                    title={t('The best {{alternative}} alternative has simple pricing', {
                      alternative
                    })}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>
                    {t('Simple and flexible pricing: Pay for what you send')}
                  </ComparisonTitle>
                  <Trans parent="p">
                    Compared to {{alternative}}, MoonMail doesn’t tie you to strict limitations on
                    how many subscribers you can have. Simply put, with MoonMail you can have as
                    many subscribers as you want and your subscription will be the same.
                  </Trans>
                </FeatureContent>
              </FeatureItem>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={FasterEmail}
                    alt={t('The best {{alternative}} alternative is fast', {alternative})}
                    title={t('The best {{alternative}} alternative is fast', {alternative})}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>{t('Cloud Solutions vs Self Hosted Servers')}</ComparisonTitle>
                  <Trans parent="p">
                    In order to setup software in a server you need to have one and if you don’t,
                    you’ll need to pay from $33 up to $1,000 depending on the size and usage of your
                    database. This implies that if there’s an update, you’ll need to patch the code
                    in your servers. With cloud solutions every change is updated in real time with
                    no patches needed.
                  </Trans>
                </FeatureContent>
              </FeatureItem>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={PersonalizationTags}
                    alt={t('The best {{alternative}} alternative uses liquid syntax')}
                    title={t('The best {{alternative}} alternative uses liquid syntax')}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>{t('Powerful Drag and Drop editor')}</ComparisonTitle>
                  <Trans parent="p">
                    Unlike {{alternative}}, MoonMail has a pre built drag and drop editor that helps
                    you creating perfectly responsive emails that look like they were designed by
                    professional designers.
                  </Trans>
                </FeatureContent>
              </FeatureItem>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={Cleaning}
                    alt={t(
                      'The best {{alternative}} alternative has a built in email list cleaning service'
                    )}
                    title={t(
                      'The best {{alternative}} alternative has a built in email list cleaning service'
                    )}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>{t('Send billions of emails faster than ever')}</ComparisonTitle>
                  <Trans parent="p">
                    {{alternative}} performance is totally bound to the power of your server. Need
                    more speed? Pay much more. The biggest magic of MoonMail is that it has the
                    maximum performance allocated to your account. Always.
                  </Trans>
                </FeatureContent>
              </FeatureItem>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={MultilingualChat}
                    alt={t(
                      'The best {{alternative}} alternative has Multilingual Live Chat Support'
                    )}
                    title={t(
                      'The best {{alternative}} alternative has Multilingual Live Chat Support'
                    )}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>{t('Multilingual Live Chat Support')}</ComparisonTitle>
                  <p>
                    <Trans>
                      Meet our team and get in touch with them whenever you need help. Careful, we
                      sometimes do support from the gym! Skip all contact forms and drop us a line
                      directly on the live chat and we will reply rapidly. Our team is multilingual,
                      and you can request support in any of the 10 languages we speak: English,
                      Español, Serbian, Russian, Catalan, Filipino, German, French, Lithuanian,
                      Polish.
                    </Trans>
                  </p>
                </FeatureContent>
              </FeatureItem>
            </FeatureList>
            <CallToAction>
              <ComparisonTitle>
                {t('Migrate Your {{alternative}} Subscribers Into MoonMail', {alternative})}
              </ComparisonTitle>
              <Trans parent="p">
                Create your account and send us a quick message in the support chat requesting the
                migration. We’ll take care of the migration for free.
              </Trans>
              <p>
                <MoonmailButton href={links.signUp} title={t('Switch to MoonMail')}>
                  {t('Switch to MoonMail')}
                </MoonmailButton>
              </p>
            </CallToAction>
          </Container>
        </Features>
      </div>
    </>
  );
};

export default Page;
